import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";
import { Navbar, Nav } from "react-bootstrap";
import styled from "styled-components";
import { GlobalStyle } from "../styles/global-style";
import NavbarLogo from "../images/logo-black.png";
import NavbarLogoWhite from "../images/logo-white.png";
import Footer from "./footer";
import PageTitle from "../reusable/page-title";

const NavLink = styled(Link)`
  color: ${({ windowwidth, color }) => windowwidth < 992 ? "black" : color} !important;
`;

const BaseLayout = ({
  title,
  navColor,
  color = "black",
  children,
  withFooter = true,
  fullWidth = false,
}) => {
  return (
    <Fragment>
      <Helmet>
        <title>WKI Innovation Lab</title>
        <meta name="description" content="WKI Innovation Lab" />
      </Helmet>

      <GlobalStyle />

      <Navbar
        bg={navColor ? navColor : typeof window !== `undefined` && window.innerWidth < 992 ? "white" : ""}
        expand="lg"
        fixed="top"
      >
        <div className="container">
          <Navbar.Brand>
            <Link className="navbar-brand" to="/#home">
              {/* <strong>WKI</strong> */}
              <img
                className="img-fluid"
                src={
                  color && typeof window !== `undefined` && window.innerWidth > 992
                    ? color === "white"
                      ? NavbarLogoWhite
                      : NavbarLogo
                    : NavbarLogo
                }
                alt="wki black logo"
              />
            </Link>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="mainNavbar" />

          <Navbar.Collapse className="justify-content-end" id="mainNavbar">
            <Nav className="navbar-nav">
              <NavLink
                windowwidth={typeof window !== `undefined` && window.innerWidth}
                color={color}
                className="nav-item nav-link active"
                to="/#home"
              >
                Home
              </NavLink>
              <NavLink
                windowwidth={typeof window !== `undefined` && window.innerWidth}
                color={color}
                className="nav-item nav-link"
                to="/lab"
              >
                Our Innovation Lab
              </NavLink>
              <NavLink
                windowwidth={typeof window !== `undefined` && window.innerWidth}
                color={color}
                className="nav-item nav-link"
                to="/services"
              >
                Services
              </NavLink>
              <NavLink
                windowwidth={typeof window !== `undefined` && window.innerWidth}
                color={color}
                className="nav-item nav-link"
                to="/portfolio"
              >
                Our Portfolio
              </NavLink>
              <NavLink
                windowwidth={typeof window !== `undefined` && window.innerWidth}
                color={color}
                className="nav-item nav-link"
                to="/contact-us"
              >
                Contact Us
              </NavLink>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>

      {title ? <PageTitle title={title} fullWidth={fullWidth} /> : ""}

      {children}

      {withFooter ? <Footer /> : ""}
    </Fragment>
  );
};

export default BaseLayout;
