import React from "react";
import { Container } from "react-bootstrap";
import {
  PageTitleLine,
  PageTitleText,
  PageTitleWrapper,
} from "../styles/global-style";
//import HlineThree from "../images/lines/hline3.png";

const PageTitle = ({ title, fullWidth = false, noMarginTop = false }) => (
  <React.Fragment>
    <Container fluid={fullWidth}>
      <PageTitleWrapper {...{ noMarginTop }}>
        <strong>
          <PageTitleText>{title}</PageTitleText>
        </strong>

        <PageTitleLine></PageTitleLine>
      </PageTitleWrapper>
    </Container>
  </React.Fragment>
);

export default PageTitle;
