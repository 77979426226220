import Image from "react-bootstrap/Image";
import styled from "styled-components";
import { SkyBlue } from "./global-style";

export const FooterElement = styled.footer`
  background-color: ${SkyBlue};
  padding: 6rem 0 3rem;
  color: #106b99;

  @media only screen and (max-width: 991px) {
    padding: 3rem 0;
  }
`;

export const FooterLogo = styled(Image)`
  max-width: 250px !important;

  @media only screen and (max-width: 768px) {
    max-width: 100%;
  }
`;

export const FooterTitle = styled.p`
  font-size: 20px;
  font-family: "montserrat-bold";
  font-weight: bold;
  margin-bottom: 1em;
`;

export const FooterIcon = styled(Image)`
  max-width: 20px;
  vertical-align: text-top;
  margin-right: 1em;
`;