import React from "react";
import { Table } from "react-bootstrap";
import CallIcon from "../images/icons/call.png";
import FaceBook from "../images/icons/facebook.png";
import Instagram from "../images/icons/instagram.png";
import MessageIcon from "../images/icons/message.png";
import Youtube from "../images/icons/youtube.png";
import BlueLogo from "../images/logo-blue.png";
import {
  FooterElement,
  FooterIcon,
  FooterLogo,
  FooterTitle,
} from "../styles/footer-style";

let translateLoaded = false;

const Footer = () => {
  // useEffect(() => {
  //   if (typeof window !== "undefined" && !translateLoaded) {
  //     const head = document.getElementsByTagName("HEAD")[0];
  //     const scr = document.createElement("script");
  //     scr.src = "https://translate.google.com/translate_a/element.js";
  //     scr.type = "text/javascript";

  //     scr.onload = () => {
  //       setTimeout(() => {
  //         window.google.translate.TranslateElement(
  //           {
  //             pageLanguage: "en",
  //             includedLanguages: "id,en",
  //             autoDisplay: false,
  //           },
  //           "google_translate_element"
  //         );

  //         translateLoaded = true;
  //       }, 1500);
  //     };

  //     head.appendChild(scr);
  //   }
  // }, []);

  return (
    <FooterElement className='section' data-anchor='footer'>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-sm-4 text-center'>
            <div className='footer-logo mb-5'>
              <FooterLogo src={BlueLogo} alt='WKI Logo' fluid />
            </div>
            <div>
              <React.Fragment></React.Fragment>
              <div id='google_translate_element'></div>
            </div>
          </div>
          <div className='col-sm-4'>
            <FooterTitle className='notranslate'>
              PT. Wahana Kosmetik Indonesia
            </FooterTitle>
            <p className='footer-desc notranslate'>
              Pergudangan Gedangan Permai Blok B 17 - 18 Jalan Gedangan, No.
              214-A, Gedangan - Sidoarjo Jawa Timur, 61254, Indonesia
            </p>

            <FooterTitle className='mb-0'>Contact Us</FooterTitle>
            <span className='footer-desc mb-3'>
              <FooterIcon src={MessageIcon} alt='message icon' />
              {"  "}maklon.ptwki@gmail.com
            </span>
            <br />
            {/* <span className="footer-desc">
              <FooterIcon src={CallIcon} alt="call icon" />
              {"  "}(031) 8191957 / 8012126
            </span>
            <br /> */}
            <span className='footer-desc'>
              <FooterIcon src={CallIcon} alt='call icon' />
              {"  "}
              <a href='https://wa.me/6281334954825'>+62 813-3495-4825</a>
            </span>

            <FooterTitle className='mt-3 mb-0'>Social Media</FooterTitle>
            <span className='footer-desc'>
              <a
                href='https://www.instagram.com/wkiinnovationlab/'
                target='_blank'>
                <FooterIcon src={Instagram} alt='instagram logo' />
              </a>
              <a href='#'>
                <FooterIcon src={FaceBook} alt='facebook logo' />
              </a>
              <a href='#'>
                <FooterIcon src={Youtube} alt='youtube logo' />
              </a>
            </span>
          </div>
          <div className='col-sm-4'>
            <FooterTitle>Opening Hours</FooterTitle>

            <Table responsive borderless size='sm' style={{ color: "#106b99" }}>
              <tbody className='notranslate'>
                <tr>
                  <td>
                    <strong>Mon</strong>
                  </td>
                  <td>: 8:00 am - 5:00 pm</td>
                </tr>

                <tr>
                  <td>
                    <strong>Tue</strong>
                  </td>
                  <td>: 8:00 am - 5:00 pm</td>
                </tr>

                <tr>
                  <td>
                    <strong>Wed</strong>
                  </td>
                  <td>: 8:00 am - 5:00 pm</td>
                </tr>

                <tr>
                  <td>
                    <strong>Thu</strong>
                  </td>
                  <td>: 8:00 am - 5:00 pm</td>
                </tr>

                <tr>
                  <td>
                    <strong>Fri</strong>
                  </td>
                  <td>: 8:00 am - 5:00 pm</td>
                </tr>

                <tr>
                  <td>
                    <strong>Sat</strong>
                  </td>
                  <td>: Closed</td>
                </tr>

                <tr>
                  <td>
                    <strong>Sun</strong>
                  </td>
                  <td>: Closed</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </FooterElement>
  );
};

export default Footer;
