import styled, { createGlobalStyle } from "styled-components";
import MontsBold from "../fonts/Graphik/GraphikBold.otf";
import MontsBoldItalic from "../fonts/Graphik/GraphikBoldItalic.otf";
import MontsMedium from "../fonts/Graphik/GraphikMedium.otf";
import MontsRegular from "../fonts/Graphik/GraphikRegular.otf";
import MontsSemiBold from "../fonts/Graphik/GraphikSemibold.otf";

export const GlobalStyle = createGlobalStyle`
  @font-face {
      font-family: "montserrat-regular";
      src: url(${MontsRegular});
  }

  @font-face {
    font-family: "montserrat-bold";
    src: url(${MontsBold});
  }

  @font-face {
    font-family: "montserrat-bold-italic";
    src: url(${MontsBoldItalic});
  }

  @font-face {
    font-family: "montserrat-semibold";
    src: url(${MontsSemiBold});
  }

  @font-face {
    font-family: "montserrat-medium";
    src: url(${MontsMedium});
  }
`;

export const PageTitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  // max-width: 18vw;
  margin-top: ${({ noMarginTop }) => (noMarginTop !== true ? "6rem" : "0")};
  margin-bottom: 2rem;
`;

export const PageTitleText = styled.h2`
  font-family: "montserrat-bold";
  font-weight: bold;
  color: black;
  margin-bottom: 0;
`;

export const PageTitleLine = styled.span`
  display: block;
  background-color: #0284c7;
  height: 6px;
  width: 50px;
  margin-bottom: 5px;
  margin-left: 10px;
`;

export const CarouselArrow = styled.img`
  cursor: pointer;
  width: 30px;
`;

export const BlueColor = "#106b99";
export const SkyBlue = "#cbe9f4";
